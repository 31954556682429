import React from 'react'

import Layout from '../../components/layout'
import Container from '../../components/container'
import PageTitle from '../../components/page-title'
import TarrifsSubNavigation from '../../components/tarrifs-sub-navigation'
import MediaContent from '../../components/media-content'
import MetaTitle from '../../components/meta-title'

// TODO: Use graphQl to retrieve the image
import giftVoucherImage from '../../assets/images/tarrifs/gift-voucher.jpg'
import MetaDescription from '../../components/meta-description'

export default function GiftVoucher () {
  return (
    <Layout>
      <MetaTitle>Informatie cadeaubon</MetaTitle>
      <MetaDescription>
        Op zoek naar een origineel cadeau? Geef een cadeaubon voor een
        fotoshoot, leuke verrassing!
      </MetaDescription>

      <div className='container-wide pt-40'>
        <PageTitle className='mb-40'>Tarieven</PageTitle>
        <TarrifsSubNavigation />
      </div>

      <Container className='pb-40'>
        <MediaContent
          className='mb-56'
          imageUrl={giftVoucherImage}
          hasImageLeft
        >
          <h1 className='text-16 font-semibold uppercase mt-0 mb-24'>
            Op zoek naar een cadeau?
          </h1>

          <p className='mb-16'>
            Een bon voor een fotoreportage is een leuke verrassing!
          </p>

          <ul className='dashed-list'>
            <li>2 jaar geldig</li>
            <li>bedrag naar keuze</li>
            <li>ruimte voor een persoonlijke boodschap</li>
          </ul>

          <p className='mt-16'>
            De cadeaubon kan digitaal of per post (+2 euro) gestuurd worden.
          </p>
        </MediaContent>
      </Container>
    </Layout>
  )
}
