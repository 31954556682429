import React from 'react'

import SubNavigation from '../sub-navigation'

const navigationItems = [
  {
    id: 'packages',
    title: 'Pakketten',
    url: '/tarieven/pakketten'
  },
  {
    id: 'gift-voucher',
    title: 'Cadeaubon',
    url: '/tarieven/cadeaubon'
  }
]

export default function TarrifsSubNavigation () {
  return <SubNavigation className='mt-0 mb-56' menuItems={navigationItems} />
}
