import React from 'react'
import cx from 'classnames'

export default function MediaContent ({
  className,
  children,
  imageUrl,
  hasImageLeft = false,
  isTopAligned = true
}) {
  const imageClasses = cx(
    hasImageLeft ? 'md:mr-24' : 'md:ml-24',
    'mt-24 md:mt-0',
    'md:w-360',
    'max-w-full',
    'flex-grow',
    'flex-shrink-0'
  )

  return (
    <section
      className={cx(
        className,
        'md:flex',
        isTopAligned ? 'items-start' : 'items-center',
        {
          'flex-row-reverse': hasImageLeft
        }
      )}
    >
      <div className={cx('flex', 'flex-grow', 'flex-col', 'max-w-600')}>
        {children}
      </div>

      {imageUrl && <img src={imageUrl} className={imageClasses} alt='' />}
    </section>
  )
}
